import {useState} from "react";

export const PerformingArtsTechRequestForm = ({ onUpdate, active }) => {

    const [formData, setFormData] = useState({
        accompanimentTracksRequired: false,
        accompanimentTracksRunBy: '',
        iPadOperator: '',
        multiSceneChangeLightingRequired: false,
        lightingRunBy: '',
        lightingOperator: '',
        scriptToOperator: false,
        appointmentNeeded: false,
        spotlightsRequired: false,
        spotlightCount: 0,
        runFromPATech: false,
        otherOperatorRequired: false,
        otherOperatorName: '',
        additionalDetails: ''
    });

    const onChange = (event) => {
        const { name, type, checked, value } = event.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        const newData = { ...formData, [name]: updatedValue };
        setFormData(newData);
        onUpdate(newData); // Pass updated data to parent
    };

    if (!active) return null; // Only render if active

    return (
        <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Performing Arts Tech Request</h3>

            <div className="mb-4">
                <label className="flex items-center">
                    <input type="checkbox" name="accompanimentTracksRequired" checked={formData.accompanimentTracksRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Accompaniment Tracks</span>
                </label>
                {formData.accompanimentTracksRequired && (
                    <div className="ml-6 mt-2 space-y-2">
                        <label className="inline-flex items-center">
                            <input type="radio" name="accompanimentTracksRunBy" value="PA Tech" checked={formData.accompanimentTracksRunBy === 'PA Tech'} onChange={onChange} className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">Run by PA Tech</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="radio" name="accompanimentTracksRunBy" value="BCC iPad" checked={formData.accompanimentTracksRunBy === 'BCC iPad'} onChange={onChange} className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">Run on BCC iPad by:</span>
                            {formData.accompanimentTracksRunBy === 'BCC iPad' && (
                                <input
                                    type="text"
                                    name="iPadOperator"
                                    value={formData.iPadOperator}
                                    onChange={onChange}
                                    className="ml-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-40 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Operator's Name"
                                />
                            )}
                        </label>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="flex items-center">
                    <input type="checkbox" name="multiSceneChangeLightingRequired" checked={formData.multiSceneChangeLightingRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Multi-scene Change Lighting Run by:</span>
                </label>
                {formData.multiSceneChangeLightingRequired && (
                    <div className="ml-6 mt-2 space-y-2">
                        <label className="inline-flex items-center">
                            <input type="radio" name="lightingRunBy" value="PA Tech" checked={formData.lightingRunBy === 'PA Tech'} onChange={onChange} className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">PA Tech</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="radio" name="lightingRunBy" value="BCC iPad" checked={formData.lightingRunBy === 'BCC iPad'} onChange={onChange} className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">BCC iPad by:</span>
                            {formData.lightingRunBy === 'BCC iPad' && (
                                <input
                                    type="text"
                                    name="lightingOperator"
                                    value={formData.lightingOperator}
                                    onChange={onChange}
                                    className="ml-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-40 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Operator's Name"
                                />
                            )}
                        </label>
                        <div className="mt-2 ml-4 space-y-2">
                            <label className="inline-flex items-center">
                                <input type="checkbox" name="scriptToOperator" checked={formData.scriptToOperator} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                                <span className="ml-2 text-gray-700">Script to operator by day before Tech Rehearsal</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input type="checkbox" name="appointmentNeeded" checked={formData.appointmentNeeded} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                                <span className="ml-2 text-gray-700">Appointment needed to set scenes with Tech</span>
                            </label>
                        </div>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="flex items-center">
                    <input type="checkbox" name="spotlightsRequired" checked={formData.spotlightsRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Spotlights</span>
                </label>
                {formData.spotlightsRequired && (
                    <div className="ml-6 mt-2 space-y-2">
                        <input
                            type="number"
                            name="spotlightCount"
                            value={formData.spotlightCount}
                            onChange={onChange}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Qty, max 2"
                            max="2"
                        />
                        <label className="inline-flex items-center">
                            <input type="checkbox" name="runFromPATech" checked={formData.runFromPATech} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">Run from PA booth/scaffolding by Tech</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="checkbox" name="otherOperatorRequired" checked={formData.otherOperatorRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <span className="ml-2 text-gray-700">Other Operator</span>
                        </label>
                        {formData.otherOperatorRequired && (
                            <input
                                type="text"
                                name="otherOperatorName"
                                value={formData.otherOperatorName}
                                onChange={onChange}
                                className="ml-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-40 sm:text-sm border-gray-300 rounded-md"
                                placeholder="Operator's Name"
                            />
                        )}
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Additional Details:</label>
                <textarea
                    name="additionalDetails"
                    value={formData.additionalDetails}
                    onChange={onChange}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Provide any additional details or requirements here."
                />
            </div>
        </div>
    );
}
