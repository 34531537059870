import {useEffect, useState} from "react";
import { format } from 'date-fns';
import {useNavigate} from "react-router-dom";
import {SERVER_URL} from "../API";

export const MyRequests = ({setCurrentTab}) => {
    const [forms, setForms] = useState([]);

    const navigate = useNavigate();

    const triggerNavigationChange = () => {
        setCurrentTab('Dashboard'); // Example: change to 'Dashboard' tab
    };

    useEffect(() => {
        // Fetch data from the Express endpoint
        fetchForms()
    }, []);

    const fetchForms = async () => {
        try {
            const response = await fetch(SERVER_URL + '/forms/troe@bethelchristianschools.com');
            const data = await response.json();
            setForms(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
    };

    const deleteForm = async (id) => {
        try {
            await fetch(`${SERVER_URL}/forms/${id}`, {
                method: 'DELETE'
            });
            fetchForms()
        } catch (error) {
            console.error('Error deleting form:', error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return format(date, 'MMMM dd, yyyy');
    };

    const editForm = (id) => {
        // triggerNavigationChange()
        navigate(`/home/form/${id}`)
    }

    const renderActionButtons = (isDraft, id) => {
        const buttons = [];

        if (isDraft) {
            buttons.push(
                // <button key="edit" onClick={() => editForm(id)} className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">Edit</button>,
                <button key="submit" className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600">Submit</button>
            );
        }

        buttons.push(
            <button key="delete" onClick={() => deleteForm(id)} className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600">Delete</button>,
            <button key="pdf" className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600">Generate PDF</button>
        );

        return buttons;
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">My Requests</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Event Name</th>
                        <th className="py-2 px-4 border-b">Created At</th>
                        <th className="py-2 px-4 border-b">Status</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {forms.map((form, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border-b">{form.title}</td>
                            <td className="py-2 px-4 border-b">{formatDate(form.createdAt)}</td>
                            <td className="py-2 px-4 border-b">{form.status}</td>
                            <td className="py-2 px-4 border-b space-x-2">
                                {renderActionButtons(form.status === 'Draft', form.id)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}