import { GoogleLogin } from '@react-oauth/google';
import {useAuth} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const LoginButton = () => {
    const { signIn, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/home');
        }
    }, [user, navigate]);

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="bg-gray-200 p-4 rounded shadow">
                <GoogleLogin
                    onSuccess={(response) => signIn(response)}
                    onError={() => console.log("Login Failed")}
                    useOneTap
                />
            </div>
        </div>
    );
};
