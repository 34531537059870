import {Calendar} from "./Calendar";

export const Dashboard = () => {

    return(
        <>
            <h1>Welcome to the Form System</h1>
            <h1>If you experience issues, please contact IT.</h1>
        </>
        // <Calendar />
    )

}