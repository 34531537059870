import {useState} from "react";

export const LiveServicesTechRequestForm = ({ onUpdate, active }) => {

    const [formData, setFormData] = useState({
        handheldMicsRequired: false,
        handheldMicCount: '',
        lapelMicsRequired: false,
        lapelMicCount: '',
        choirMicsRequired: false,
        choirMicCount: '',
        backWallLights: false,
        frontOnLights: false,
        sideOnLights: false,
        tracksCD: false,
        tracksiPhone: false,
        tracksiPad: false,
        keyboardRequired: false,
        drumsRequired: false,
        otherInstrumentRequired: false,
        otherInstrument: '',
        songLyricsProjection: false,
        powerpointPresentation: false,
        videoRequired: false,
        livestreamingRequired: false,
        videoRecordingRequired: false,
        techPersonnelRequired: false
    });

    const onChange = (event) => {
        const { name, type, checked, value } = event.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        const newData = { ...formData, [name]: updatedValue };
        setFormData(newData);
        onUpdate(newData); // Pass updated data to parent
    };

    if (!active) return null; // Only render if active

    return (
        <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Live Services Tech Request</h3>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="handheldMicsRequired" checked={formData.handheldMicsRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Handheld Mics Required</span>
                </label>
                {formData.handheldMicsRequired && (
                    <input
                        type="number"
                        name="handheldMicCount"
                        value={formData.handheldMicCount}
                        onChange={onChange}
                        className="ml-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Qty, max 8"
                        max="8"
                    />
                )}
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="lapelMicsRequired" checked={formData.lapelMicsRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Lapel/Headset Mics Required</span>
                </label>
                {formData.lapelMicsRequired && (
                    <input
                        type="number"
                        name="lapelMicCount"
                        value={formData.lapelMicCount}
                        onChange={onChange}
                        className="ml-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Qty, max 8"
                        max="8"
                    />
                )}
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="choirMicsRequired" checked={formData.choirMicsRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Choir Mics Required</span>
                </label>
                {formData.choirMicsRequired && (
                    <input
                        type="number"
                        name="choirMicCount"
                        value={formData.choirMicCount}
                        onChange={onChange}
                        className="ml-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Qty, max 4"
                        max="4"
                    />
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Stage Lights:</label>
                <div className="space-y-2">
                    <label className="inline-flex items-center">
                        <input type="checkbox" name="backWallLights" checked={formData.backWallLights} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">Back Wall</span>
                    </label>
                    <label className="inline-flex items-center ml-4">
                        <input type="checkbox" name="frontOnLights" checked={formData.frontOnLights} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">Front On</span>
                    </label>
                    <label className="inline-flex items-center ml-4">
                        <input type="checkbox" name="sideOnLights" checked={formData.sideOnLights} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">Side On</span>
                    </label>
                </div>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Accompaniment Tracks:</label>
                <div className="space-y-2">
                    <label className="inline-flex items-center">
                        <input type="checkbox" name="tracksCD" checked={formData.tracksCD} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">CD</span>
                    </label>
                    <label className="inline-flex items-center ml-4">
                        <input type="checkbox" name="tracksiPhone" checked={formData.tracksiPhone} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">iPhone</span>
                    </label>
                    <label className="inline-flex items-center ml-4">
                        <input type="checkbox" name="tracksiPad" checked={formData.tracksiPad} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        <span className="ml-2 text-gray-700">iPad</span>
                    </label>
                </div>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="keyboardRequired" checked={formData.keyboardRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Keyboard</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="drumsRequired" checked={formData.drumsRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Drums</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="otherInstrumentRequired" checked={formData.otherInstrumentRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Other Instrument</span>
                </label>
                {formData.otherInstrumentRequired && (
                    <input
                        type="text"
                        name="otherInstrument"
                        value={formData.otherInstrument}
                        onChange={onChange}
                        className="ml-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Specify instrument"
                    />
                )}
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="songLyricsProjection" checked={formData.songLyricsProjection} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Song lyrics needing projection</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="powerpointPresentation" checked={formData.powerpointPresentation} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Powerpoint Presentation w/ clicker</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="videoRequired" checked={formData.videoRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Video</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="livestreamingRequired" checked={formData.livestreamingRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Livestreaming</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="videoRecordingRequired" checked={formData.videoRecordingRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Video Recording</span>
                </label>
            </div>

            <div className="mb-4 flex items-center">
                <label className="flex items-center">
                    <input type="checkbox" name="techPersonnelRequired" checked={formData.techPersonnelRequired} onChange={onChange} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    <span className="ml-2 text-gray-700">Tech Personnel Required</span>
                </label>
            </div>
        </div>
    );
}