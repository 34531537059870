import React, {createContext, useContext, useEffect, useState} from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import {jwtDecode} from "jwt-decode";

const AuthContext = createContext({
    user: null,
    signIn: () => {},
    signOut: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check if there is a user stored in localStorage when the component mounts
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const signIn = (response) => {
        console.log('Login Success:', response);
        if (response.credential) {
            const userPayload = jwtDecode(response.credential)
            const userData = {
                name: userPayload.name, // Assuming the response object has a name field
                email: userPayload.email, // Assuming the response object has an email field
                picture: userPayload.picture
            }
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
        }

    };

    const signOut = () => {
        console.log('Logged out');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

const clientId = "273944291151-i9cmblf29188vhb7csmggrnikp9i6j29.apps.googleusercontent.com";

export const AuthProviderWrapper = ({ children }) => (
    <GoogleOAuthProvider clientId={clientId}>
        <AuthProvider>
            {children}
        </AuthProvider>
    </GoogleOAuthProvider>
);
