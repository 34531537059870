import * as React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";

const styles = {
    border: "0.0625rem solid #9c9c9c",
    borderRadius: "0.25rem",
};


export const PlatformCanvasDrawer = forwardRef((props, ref) => {
    const [backgroundImage] = useState(
        `${process.env.PUBLIC_URL}/platform_2x.png`,
    );

    const canvasRef = useRef(null);

    useImperativeHandle(ref, () => ({
        exportImage: () => {
            if (canvasRef.current) {
                return canvasRef.current.exportImage('png', {width: 1250, height: 300});
            }
        },
        undo: (e) => {
            if (canvasRef.current) {
                canvasRef.current.undo();
            }
        },
        erase: (e) => {
            if (canvasRef.current) {
                canvasRef.current.clearCanvas();
            }
        }
    }));

    return (
        <div>
            <ReactSketchCanvas
                height={300}
                width={1250}
                ref={canvasRef}
                style={styles}
                strokeWidth={2}
                strokeColor="black"
                backgroundImage={backgroundImage}
                exportWithBackgroundImage={true}
            />
            <div className={"flex space-x-4"} style={{ marginTop: '10px' }}>
                <button type="button" className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"} onClick={() => ref.current.undo()}>Undo</button>
                <button type="button" className={"bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"} onClick={() => ref.current.erase()}>Erase</button>
            </div>
        </div>
    );
});