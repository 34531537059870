import {useState} from "react";

export const EquipmentRequest = ({onUpdate, active}) => {

    const [equipmentRequestForm, setEquipmentRequestForm] = useState({
        tables6ft: '',
        tables8ft: '',
        chairs: '',
        otherEquipment: ''
        // Define other fields as necessary
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newData = { ...equipmentRequestForm, [name]: value };
        setEquipmentRequestForm(newData);
        onUpdate(newData); // Pass updated data to parent
    };

    if (!active) return null; // Only render if active

    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Equipment Requests</h3>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Tables (6' Rectangle):</label>
                <input
                    type="number"
                    name="tables6ft"
                    value={equipmentRequestForm.tables6ft}
                    onChange={handleChange}
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter number of 6' rectangle tables"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Tables (8' Rectangle):</label>
                <input
                    type="number"
                    name="tables8ft"
                    value={equipmentRequestForm.tables8ft}
                    onChange={handleChange}
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter number of 8' rectangle tables"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Chairs:</label>
                <input
                    type="number"
                    name="chairs"
                    value={equipmentRequestForm.chairs}
                    onChange={handleChange}
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter number of chairs"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Other Equipment:</label>
                <input
                    type="text"
                    name="otherEquipment"
                    value={equipmentRequestForm.otherEquipment}
                    onChange={handleChange}
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Specify any other equipment needed"
                />
            </div>
        </div>
    );
}