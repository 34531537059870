import {useEffect, useRef, useState} from "react";
import {CampusLockUp} from "./Forms/campus-lock-up";
import {EquipmentRequest} from "./Forms/equipment-request";
import {PlatformChangeRequestForm} from "./Forms/platform-change-request";
import {LiveServicesTechRequestForm} from "./Forms/LiveServiceTechRequest";
import {PerformingArtsTechRequestForm} from "./Forms/PerformArtsTechRequest";
import axios from "axios";
import {useAuth} from "./AuthContext";
import {PlatformCanvasDrawer} from "./Forms/PlatformCanvasDrawer";
import {useNavigate, useParams} from "react-router-dom";
import {SERVER_URL} from "../API";

export const MasterCalendarForm = () => {

    const { id } = useParams();
    const [formData, setFormData] = useState({
        eventName: '',
        department: '', // Assuming this holds the selected department
        dayDateOfEvent: '',
        startTime: '',
        endTime: '',
        prepTime: '', // Assuming this is what you meant by 'timeReservedForPrep'
        locationRequested: '',
        estimatedAttendance: '',
        criticalThinkers: false,
        competentCommunicators: false,
        communityContributors: false,
        lifeLongLearners: false,
        christCentered: false,
        sloExplanation: '',
        additionalDetails: '', // Assuming there should be a field for this in your state
        campusLockUp: false, // Assuming this checkbox represents a need for campus lock-up
        equipmentRequest: false, // Assuming this checkbox represents a need for equipment requests
        platformChangeRequest: false, // Assuming this checkbox represents a need for platform change requests
        liveServicesTechRequest: false, // Assuming this checkbox represents a need for live services/performing arts tech requests
        securityPersonnel: false, // Assuming this checkbox represents a need for security personnel
        sprinklerAdjustment: false, // Assuming this checkbox represents a need for sprinkler adjustment
        trafficConeAdjustment: false, // Assuming this checkbox represents a need for traffic cone adjustment,
        campusLockUpForm: {},
        equipmentRequestForm: {},
        platformChangeRequestForm: {},
        liveServicesTechRequestForm: {},
        performingArtsTechRequestForm: {}
    });

    const user = JSON.parse(localStorage.getItem('user'));
    const platformRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${SERVER_URL}/forms/id/${id}`);
                const data = await response.json();
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ...data
                }));
            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (event) => {
        const { name, type, value, checked } = event.target;
        // Check the input type to determine if the value should be taken from 'value' or 'checked'
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const handleUpdate = (childName, childData) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [childName]: childData
        }));
    };

    const handleSubmit = async () => {
        try {
            let platformData = null
            if (platformRef.current != null) {
                platformData = await platformRef.current.exportImage()
            }
            await axios.post(`${SERVER_URL}/save-form`, {owner: user.email, type: "MasterCalendar", formData: formData, platformImage: platformData});
            alert('Form data saved successfully');
            navigate('/home/request');
        } catch (error) {
            console.error('Error saving form data', error);
            alert('Error saving form data');
        }
    };

    const handleSaveDraft = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/save-form`, {owner: user.email, type: "MasterCalendar", formData: formData, isDraft: true});
            alert('Form data saved successfully');
            navigate('/home/request');
        } catch (error) {
            console.error('Error saving form data', error);
            alert('Error saving form data');
        }
    }

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetch(`${SERVER_URL}/rooms`)
            .then(response => response.json())
            .then(data => setLocations(data))
            .catch(error => console.error('Error fetching locations:', error));
    }, []);

    const [departments, setDepartments] = useState([]);
    useEffect(() => {
        fetch(`${SERVER_URL}/departments`)
            .then(response => response.json())
            .then(data => setDepartments(data))
            .catch(error => console.error('Error fetching locations:', error));
    }, [])

    return(
        <div className={"mx-20"}>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="space-y-8">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">On-Campus Event Request Form</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Event</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required placeholder={"First Day of School"} type="text" name="eventName" id="eventName" value={formData.eventName} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Department</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <select required name="department" id="department" value={formData.department} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                        {departments.map(department => (
                                            <option key={department.id} value={department.name}>{department.name}</option>
                                        ))}
                                    </select>
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Day & Date of Event</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required type="date" name="dayDateOfEvent" id="dayDateOfEvent" value={formData.dayDateOfEvent} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Start Time</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required type="time" name="startTime" id="startTime" value={formData.startTime} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">End Time</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required type="time" name="endTime" id="endTime" value={formData.endTime} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Time Reserved for Prep</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required type="time" name="prepTime" id="prepTime" value={formData.prepTime} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Location(s) Requested</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <select required name="locationRequested" id="locationRequested" value={formData.locationRequested} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                        {locations.map(location => (
                                            <option key={location.id} value={location.name}>{location.name}</option>
                                        ))}
                                    </select>
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Estimated Attendance</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input required type="number" name="estimatedAttendance" id="estimatedAttendance" value={formData.estimatedAttendance} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">SLOs Alignment (school)</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <fieldset>
                                        <legend className="sr-only">SLOs Alignment</legend>
                                        <div className="space-y-5">
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="criticalThinkers" checked={formData.criticalThinkers} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="criticalThinkers" className="font-medium text-gray-700">Critical & Creative Thinkers</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="competentCommunicators" checked={formData.competentCommunicators} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="competentCommunicators" className="font-medium text-gray-700">Competent Communicators</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="communityContributors" checked={formData.communityContributors} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="communityContributors" className="font-medium text-gray-700">Community Contributors</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="lifeLongLearners" checked={formData.lifeLongLearners} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="lifeLongLearners" className="font-medium text-gray-700">Life-long Learners</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="christCentered" checked={formData.christCentered} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="christCentered" className="font-medium text-gray-700">Christ-Centered</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">SLOs Alignment Explanation</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <textarea name="sloExplanation" value={formData.sloExplanation} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Explain how your event aligns with the SLOs."></textarea>
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Additional Services Needed</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <fieldset>
                                        <legend className="sr-only">Additional Services</legend>
                                        <div className="space-y-5">
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="campusLockUp" checked={formData.campusLockUp} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="campusLockUp" className="font-medium text-gray-700">Campus Lock-Up</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="equipmentRequest" checked={formData.equipmentRequest} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="equipmentRequest" className="font-medium text-gray-700">Equipment Request</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="platformChangeRequest" checked={formData.platformChangeRequest} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="platformChangeRequest" className="font-medium text-gray-700">Platform Change Request</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="liveServicesTechRequest" checked={formData.liveServicesTechRequest} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="liveServicesTechRequest" className="font-medium text-gray-700">Live Services/Performing Arts Tech Request</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="securityPersonnel" checked={formData.securityPersonnel} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="securityPersonnel" className="font-medium text-gray-700">Security Personnel</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="sprinklerAdjustment" checked={formData.sprinklerAdjustment} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="sprinklerAdjustment" className="font-medium text-gray-700">Sprinkler Adjustment</label>
                                                </div>
                                            </div>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input type="checkbox" name="trafficConeAdjustment" checked={formData.trafficConeAdjustment} onChange={handleChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="trafficConeAdjustment" className="font-medium text-gray-700">Traffic Cone Adjustment</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </dd>
                            </div>

                            {/* Conditional Forms */}
                            <div className={`transition-opacity duration-500 ${formData.campusLockUp ? 'opacity-100' : 'opacity-0'}`}>
                                <CampusLockUp onUpdate={(data) => handleUpdate('campusLockUpForm', data)} active={formData.campusLockUp} />
                            </div>
                            <div className={`transition-opacity duration-500 ${formData.equipmentRequest ? 'opacity-100' : 'opacity-0'}`}>
                                <EquipmentRequest onUpdate={(data) => handleUpdate('equipmentRequestForm', data)} active={formData.equipmentRequest} />
                            </div>
                            <div className={`transition-opacity duration-500 ${formData.platformChangeRequest ? 'opacity-100' : 'opacity-0'}`}>
                                <PlatformChangeRequestForm ref={platformRef} onUpdate={(data) => handleUpdate('platformChangeRequestForm', data)} active={formData.platformChangeRequest} />
                            </div>
                            <div className={`transition-opacity duration-500 ${formData.liveServicesTechRequest ? 'opacity-100' : 'opacity-0'}`}>
                                <LiveServicesTechRequestForm onUpdate={(data) => handleUpdate('liveServicesTechRequestForm', data)} active={formData.liveServicesTechRequest} />
                            </div>
                            <div className={`transition-opacity duration-500 ${formData.liveServicesTechRequest ? 'opacity-100' : 'opacity-0'}`}>
                                <PerformingArtsTechRequestForm onUpdate={(data) => handleUpdate('performingArtsTechRequestForm', data)} active={formData.liveServicesTechRequest} />
                            </div>

                        </dl>
                    </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="button" onClick={handleSaveDraft} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Save as Draft
                    </button>
                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )

}