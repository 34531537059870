import React, {useEffect, useState} from 'react';
import Calendar from './Calendar';
import {SERVER_URL} from "../API";
import {DayView} from "./DayView/DayView";
import * as dayjs from "dayjs";

export const RoomScheduler = () => {
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [events, setEvents] = useState([]);

    const [locations, setLocations] = useState([]);
    const [dates, setDates] = useState([]);
    const [dateMapper, setDateMapper] = useState(null);

    const [today, setToday] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);

    useEffect(() => {
        const startOfMonth = dayjs(new Date(selectedYear, selectedMonth, 1));
        const endOfMonth = startOfMonth.endOf('month');
        const startDayOfWeek = startOfMonth.day();
        const endDayOfWeek = endOfMonth.day();

        const dates = []

        for (let i = startDayOfWeek; i > 0; i--) {
            dates.push(startOfMonth.subtract(i, 'day').format('YYYY-MM-DD'));
        }

        for (let i = 1; i <= endOfMonth.date(); i++) {
            dates.push(dayjs(new Date(selectedYear, selectedMonth, i)).format('YYYY-MM-DD'));
        }

        for (let i = 1; i < 7 - endDayOfWeek; i++) {
            dates.push(endOfMonth.add(i, 'day').format('YYYY-MM-DD'));
        }

        setDates(dates)
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        const now = dayjs()
        const today = now.format('YYYY-MM-DD');
        const currentMonth = now.month();
        const currentYear = now.year();

        const mappedDates = dates.map(date => {
            const dayjsDate = dayjs(date);
            const isCurrentMonth = dayjsDate.month() === currentMonth && dayjsDate.year() === currentYear;
            const isToday = date === today;

            return {
                date,
                isCurrentMonth,
                isToday,
                isSelected: false
            }
        })

        setDateMapper(mappedDates);

    }, [dates]);

    useEffect(() => {
        fetch(`${SERVER_URL}/rooms`)
            .then(response => response.json())
            .then(data => setLocations(data))
            .catch(error => console.error('Error fetching locations:', error));
    }, []);

    useEffect(() => {
        const today = dayjs()
        const year = today.year()
        const month = today.month()
        setSelectedYear(year)
        setSelectedMonth(month)
    }, [])

    const handleDateClick = (date) => {
        setSelectedDate(date)
    }

    return (
        <div>
            {dateMapper != null
                && dateMapper.length > 0
                && selectedMonth != null
                && selectedYear != null
                && <DayView month={selectedMonth} year={selectedYear} days={dateMapper}/>
            }
        </div>
    )
}