import logo from './logo.svg';
import './App.css';
import React from 'react';
import {BrowserRouter as Router, Link, Route, Routes, Switch} from 'react-router-dom';
// import Home from './components/Home';
import {AuthProviderWrapper} from "./components/AuthContext";
import {LoginButton} from "./components/LoginButton";
import UserProfile from "./components/UserProfile";
import MainView from "./MainView";
import {Dashboard} from "./components/Dashboard";
import {MyRequests} from "./components/MyRequests";
import {MasterCalendarForm} from "./components/MasterCalendarForm";
import {Administrator} from "./components/Administrator";
import {RoomScheduler} from "./components/RoomScheduler";

function App() {
    return (
        <AuthProviderWrapper>
            <Router>
                <div>
                    <Routes>
                        <Route path="/" element={<LoginButton />} />
                        {/*<Route path="/profile" element={<UserProfile />} />*/}
                        <Route path="/home" element={<MainView />}>
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="request" element={<MyRequests />} />
                            <Route path="administrator" element={<Administrator />} />
                            <Route path="form/:id" element={<MasterCalendarForm />} />
                            <Route path="form" element={<MasterCalendarForm />} />
                            <Route path="schedule" element={<RoomScheduler />} />
                        </Route>
                    </Routes>
                </div>
            </Router>
        </AuthProviderWrapper>
    );

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
