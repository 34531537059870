import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/20/solid'
import {useEffect, useState} from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const Calendar = () => {
    const today = new Date();
    const currentMonth = today.toLocaleString('default', {month: 'long'});
    const currentYear = today.getFullYear();
    const currentDate = today.getDate();

    const [selectedDate, setSelectedDate] = useState(today);
    const [days, setDays] = useState([]);

    useEffect(() => {
        // Logic to generate days for the current month
        const daysInMonth = new Date(currentYear, today.getMonth() + 1, 0).getDate();
        const startDay = new Date(currentYear, today.getMonth(), 1).getDay();
        const daysArray = [];

        for (let i = 0; i < startDay; i++) {
            daysArray.push({date: '', isCurrentMonth: false, isToday: false, events: []});
        }

        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push({
                date: `${currentYear}-${today.getMonth() + 1}-${i}`,
                isCurrentMonth: true,
                isToday: i === currentDate,
                events: [],
            });
        }

        setDays(daysArray);
    }, [currentMonth, currentYear, currentDate]);

    const handlePreviousMonth = () => {
        // Logic to handle previous month
        const previousMonth = new Date(currentYear, today.getMonth() - 1);
        setSelectedDate(previousMonth);
    };

    const handleNextMonth = () => {
        // Logic to handle next month
        const nextMonth = new Date(currentYear, today.getMonth() + 1);
        setSelectedDate(nextMonth);
    };

    return (
        <div className="lg:flex lg:h-full lg:flex-col">
            <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                    <time dateTime={`${currentYear}-${today.getMonth() + 1}`}>{`${currentMonth} ${currentYear}`}</time>
                </h1>
                <div className="flex items-center space-x-4">
                    <button
                        type="button"
                        onClick={handlePreviousMonth}
                        className="flex h-9 w-9 items-center justify-center rounded-md border border-gray-300 text-gray-400 hover:text-gray-500 focus:relative"
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        onClick={handleNextMonth}
                        className="flex h-9 w-9 items-center justify-center rounded-md border border-gray-300 text-gray-400 hover:text-gray-500 focus:relative"
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </header>
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-4">
                        M<span className="sr-only sm:not-sr-only">on</span>
                    </div>
                    <div className="bg-white py-4">
                        T<span className="sr-only sm:not-sr-only">ue</span>
                    </div>
                    <div className="bg-white py-4">
                        W<span className="sr-only sm:not-sr-only">ed</span>
                    </div>
                    <div className="bg-white py-4">
                        T<span className="sr-only sm:not-sr-only">hu</span>
                    </div>
                    <div className="bg-white py-4">
                        F<span className="sr-only sm:not-sr-only">ri</span>
                    </div>
                    <div className="bg-white py-4">
                        S<span className="sr-only sm:not-sr-only">at</span>
                    </div>
                    <div className="bg-white py-4">
                        S<span className="sr-only sm:not-sr-only">un</span>
                    </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                        {days.map((day) => (
                            <div
                                key={day.date}
                                className={classNames(
                                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                    'relative px-3 py-4' // Increase py-2 to py-4
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={
                                        day.isToday
                                            ? 'flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white' // Increase h-6 w-6 to h-8 w-8
                                            : undefined
                                    }
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                {day.events.length > 0 && (
                                    <ol className="mt-2">
                                        {day.events.slice(0, 2).map((event) => (
                                            <li key={event.id}>
                                                <a href={event.href} className="group flex">
                                                    <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                                        {event.name}
                                                    </p>
                                                    <time
                                                        dateTime={event.datetime}
                                                        className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                                    >
                                                        {event.time}
                                                    </time>
                                                </a>
                                            </li>
                                        ))}
                                        {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                                    </ol>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {days.map((day) => (
                            <button
                                key={day.date}
                                type="button"
                                className={classNames(
                                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                    (day.isSelected || day.isToday) && 'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected && day.isToday && 'text-indigo-600',
                                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                    'flex h-20 flex-col px-3 py-4 hover:bg-gray-100 focus:z-10' // Increase h-14 to h-20 and py-2 to py-4
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        day.isSelected && 'flex h-8 w-8 items-center justify-center rounded-full', // Increase h-6 w-6 to h-8 w-8
                                        day.isSelected && day.isToday && 'bg-indigo-600',
                                        day.isSelected && !day.isToday && 'bg-gray-900',
                                        'ml-auto'
                                    )}
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                <span className="sr-only">{day.events.length} events</span>
                                {day.events.length > 0 && (
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                        <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                    ))}
                  </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
