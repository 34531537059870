import {useState, Fragment} from "react";
import {Listbox, Transition} from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';

export const CampusLockUp = ({onUpdate, active}) => {

    const [campusLockUpForm, setCampusLockUpForm] = useState({
        lockUpStaffType: '',
        lockUpStaffName: '',
        // Define other fields as necessary
    });

    const staffTypes = [
        { id: 'janitorial', name: 'Janitorial Staff (before 8:00 PM)' },
        { id: 'school', name: 'School Staff (after 8:00 PM)' },
        { id: 'church', name: 'Church Staff (after 8:00 PM)' },
    ];

    const [selectedStaffType, setSelectedStaffType] = useState(campusLockUpForm.lockUpStaffType);

    const handleStaffTypeChange = (value) => {
        setSelectedStaffType(value);
        handleChange({ target: { name: 'lockUpStaffType', value: value.id } });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newData = { ...campusLockUpForm, [name]: value };
        setCampusLockUpForm(newData);
        onUpdate(newData); // Pass updated data to parent
    };

    if (!active) return null; // Only render if active

    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Campus Lock-Up</h3>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Staff Type:</label>
                <Listbox value={selectedStaffType} onChange={handleStaffTypeChange}>
                    {({ open }) => (
                        <>
                            <div className="relative mt-1">
                                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <span className="block truncate">{selectedStaffType ? selectedStaffType.name : 'Select Staff Type'}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {staffTypes.map((staffType) => (
                                            <Listbox.Option
                                                key={staffType.id}
                                                className={({ active }) =>
                                                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900'
                                                    }`
                                                }
                                                value={staffType}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                            {staffType.name}
                                                        </span>
                                                        {selected ? (
                                                            <span
                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                    active ? 'text-white' : 'text-indigo-600'
                                                                }`}
                                                            >
                                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Staff Name:</label>
                <input
                    type="text"
                    name="lockUpStaffName"
                    value={campusLockUpForm.lockUpStaffName}
                    onChange={handleChange}
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter staff name"
                    required
                />
            </div>
        </div>
    );

}