import {forwardRef, useState} from "react";
import {PlatformCanvasDrawer} from "./PlatformCanvasDrawer";

export const PlatformChangeRequestForm = forwardRef(({onUpdate, active}, ref) => {

    const [formData, setFormData] = useState({
        platformDescription: '',
        platformDiagram: null // This will store the file object for a diagram
    });

    const onChange = (event) => {
        const { name, value } = event.target;
        const newData = { ...formData, [name]: value };
        setFormData(newData);
        onUpdate(newData); // Pass updated data to parent
    };

    const onFileChange = (event) => {
        const { name, files } = event.target;
        const newData = { ...formData, [name]: files[0] };
        setFormData(newData);
        onUpdate(newData); // Pass updated file data to parent
    };

    if (!active) return null; // Only render if active

    return (
        <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Platform Change Requests</h3>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Platform Setup Description:</label>
                <textarea
                    name="platformDescription"
                    value={formData.platformDescription}
                    onChange={onChange}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Describe what the platform needs to look like."
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">Draw Diagram:</label>
                <PlatformCanvasDrawer ref={ref} />
                {/*<input*/}
                {/*    type="file"*/}
                {/*    name="platformDiagram"*/}
                {/*    onChange={onFileChange}*/}
                {/*    className="block w-full text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"*/}
                {/*/>*/}
            </div>
        </div>
    );
});